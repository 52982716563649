.list {
  list-style: none;
  margin: 1em 0;
  @include flex(flex-start, flex-start);
  flex-direction: column;
  width: 100%;
  line-height: 1.5em;

  // .listItem
  &Item {
    margin: 0.5em 0;
    color: $subtitlecolor;
  }

  &.horizontal {
    flex-direction: row;
    flex-wrap: wrap;

    .listItem {
      &:not(:last-of-type) {
        margin-right: 1em;
      }
    }
  }

  &.numbered {
    list-style: none;
    counter-reset: my-awesome-counter;

    .listItem {
      counter-increment: my-awesome-counter;
      @include flex(flex-start, center);

      &:before {
        content: counter(my-awesome-counter);
        @include flex(center, center);
        background-color: $primary;
        font-size: 0.8rem;
        color: $white;
        width: 1.6rem;
        height: 1.6rem;
        border-radius: 50%;
        margin-right: 1rem;
      }
    }
  }
  &.bulleted {
    .listItem {
      @include flex(flex-start, center);
      cursor: default;
      &:before {
        content: '';
        display: block;
        width: 0; 
        height: 0; 
        border-top: 0.5rem solid transparent;
        border-bottom: 0.5rem solid transparent;
        border-left: 1rem solid $primary;
        margin-right: 1rem;
        transition: transform 0.15s ease-out;
      }

      &:hover, &:focus {
        &:before {
          transform: translateX(0.5rem);
        }
      }
    }
  }

  &.lined {
    width: 100%;
    @include flex(flex-start, flex-start);
    flex-direction: column;
    .listItem {
      @include flex(flex-start, center);
      width: 100%;
      border-bottom: 1px solid rgba($black, 0.1);
      line-height: 1.5em;
      margin: 0 !important;
      padding: 0.5rem 0;
    }
  }

  &.light {
    .list {
      // .listItem
      &Item {
        margin: 0.5em 0;
        color: rgba($white, 0.8);
      }
    }
    &.lined {
      .list {
        // .listItem
        &Item {
          border-bottom: 1px solid rgba($white, 0.1);
        }
      }
    }
  }
}