.section {
  // @include flex(flex-start, stretch);
  perspective: 100vw;
  width: 100%;
  position: relative;
  z-index: 1;
  min-height: 100vh;
  line-height: 1.4;
  
  background-color: $bgcolor;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
  background-origin: border-box;
  background-clip: border-box;

  &:first-of-type {
    padding-top: 70px;
  }

  &.auto {
    min-height: auto;
  }

  &.overlay {
    &:before {
      @include overlay(2, 0.5, $primary, absolute);
    }
    &--secondary {
      &:before {
        @include overlay(2, 0.5, $secondary, absolute);
      }
    }
    &--tertiary {
      &:before {
        @include overlay(2, 0.5, $tertiary, absolute);
      }
    }
    &--black {
      &:before {
        @include overlay(2, 0.5, $black, absolute);
      }
    }
  }

  .inbetweener {
    flex-grow: 1;
    &.top {
      position: relative;
      width: 100%;
    }
  }

  h1, h2, h3, h4, h5, h6 {
    color: $titlecolor;
    line-height: 1.75em;
  }

  p {
    color: $subtitlecolor;
    line-height: 1.75em;
  }

  // .sectionWrapper
  &Wrapper {
    width: 100%;
    position: relative;
    padding: 4rem;
    z-index: 2;
  }

  // .section--hero
  &--hero {
    @include flex(flex-start, stretch);
    color: $white;
    min-height: 100vh;

    background-color: $secondary;
    background-image: url('/image/hero.jpg');
    padding-top: 0px !important;

    .sectionWrapper {
      @include flex(flex-start, center);
      width: 100%;
    }

    .heroContent {
      width: 100%;
      max-width: 40rem;
      padding: 2rem 4rem;

      @media #{$responsive-phone} {
        padding: 2rem 0rem;
      }

      h1 {
        font-size: 3rem;
        text-transform: uppercase;
        letter-spacing: -0.1rem;
        color: $white;
        margin-bottom: 0;
        @media #{$responsive-phone} {
          font-size: 2rem;
        }
      }

      p {
        font-size: 1.5rem;
        color: rgba($white, 0.75);
        span {
          display: block;
        }
      }
    }
  }

  // .section--homeabout
  &--homeabout {
    background-color: $white;
    background-image: url('/image/bg_about.jpg');

    &:before {
      content: '';
      display: block;
      position: absolute;
      z-index: 2;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(to top, rgba($white, 1), rgba($white, 0));
    }

    .sectionWrapper {
      position: relative;
      z-index: 2;
    }

    .illustration {
      width: 50%;
      float: right;

      @media #{$responsive-phone} {
        width: 100%;
        float: none;
        clear: both;

        lottie-player {
          height: 300px !important;
        }
      }
    }

    .bigLogoContainer {
      @include flex(flex-start, center);
    }
    .bigLogo {
      width: min(300px, 100%);
      height: auto;
      border-radius: 50%;
      background-color: rgba($white, 0.15);
      object-fit: contain;
      padding: 2rem;
    }
  }

  // .section--homeservices
  &--homeservices {
    background-color: $lightgray;

    .iconLinks {
      margin-top: 4rem;
    }

    .iconLink {
      @include inline-flex(center, center);
      flex-direction: column;
      position: relative;
    }

    .sparky {
      position: relative;
      outline: none;
      .spark {
        width: 100%;
        position: absolute;
        z-index: 2;
        top: 50%;
        left: 50%;
        transform: translate(-50%, calc(-50% - 2.5rem));
        display: none;
        pointer-events: none;
      }

      &:hover, &:focus, &.hover {
        .spark {
          display: block;
        }
        .icon {
          transform: scale(1.2);
        }
      }
    }

    .icon {
      width: 100%;
      max-width: 8rem;
      height: auto;
      margin-bottom: 1rem;
      transform: scale(1);
      transition: transform 0.3s $bounce;
    }
  }

  // .section--company
  &--company {
    background-color: $white;
    background-image: url('/image/bg_company.jpg');
  }

  // .section--tabby
  &--tabby {
    .sticky {
      position: sticky;
      top: 2rem;

      @media #{$responsive-phone} {
        position: relative;
        top: auto;
      }
    }

    .section-title {
      text-transform: uppercase;
      color: $primary;
      font-weight: 700;
    }

    .sectionWrapper {
      padding: 4rem 0rem;
      @media #{$responsive-phone} {
        padding-top: 0;
      }
    }

    .tabWrapper {
      padding: 0 4rem;
      padding-top: 1rem;
    }
    .tabContentWrapper {
      padding: 0;
      background-image: url('/image/watermark.png'), linear-gradient(45deg, rgba($secondary, 1) -20%, rgba($primary, 1));
      background-repeat: no-repeat, no-repeat;
      background-position: bottom right, bottom right;
      background-attachment: fixed, scroll;
      box-shadow: -1rem 1rem 2rem 0.25rem rgba($black, 0.3);
    }

    .tab {
      &Item {
        margin: 0.5rem 0;
      }
      &Link {
        font-size: 1.25rem;
        font-weight: bold;
      }
    }

    .tabContent {
      min-height: 600px;
      * {
        color: $white;
      }
      a:not(.btn) {
        color: $yellow;
        &:hover, &:focus {
          color: lighten($yellow, 10%);
        }
      }
      h6 {
        margin-top: 2rem;
      }
      p {
        span {
          display: block;
        }
      }
    }

    .tabPageHeader {
      position: relative;
      border-bottom-left-radius: 5rem;
      overflow: hidden;
      @media #{$responsive-phone} {
        border-radius: 0;
      }
      h1 {
        position: absolute;
        z-index: 3;
        bottom: 1rem;
        left: 5rem;
        color: $white;
        width: 100%;
        display: block;
        margin: 0;
        @media #{$responsive-phone} {
          left: 2rem;
        }
      }
      img {
        position: relative;
        z-index: 1;
        width: 100%;
        height: min(30vh, 220px);
        object-fit: cover;

        @media #{$responsive-phone} {
          min-height: 200px;
          object-fit: cover;
        }
      }
      
      &:before {
        content: '';
        display: block;
        position: absolute;
        z-index: 2;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(to top, rgba($black, 0.75), rgba($black, 0));
      }
    }

    .tabPageWrapper {
      padding: 2rem 4rem;
      @media #{$responsive-phone} {
        padding: 0 1rem;
        padding-top: 0;
      }
      > .row {
        margin: 0;
      }
    }

    .list.numbered {
      display: block !important;
      columns: 3 150px;
      column-gap: 2rem;
      .listItem {
        width: auto;
        margin: 0.5rem 0 !important;
        break-inside: avoid;
      }
    }

    .executives {
      .row {
        margin: 0;
      }

      &.main {
        // background-color: rgba($white, 0.05);
        border-bottom: 1px solid rgba($white, 0.25);
        margin-bottom: 2rem;
      }

      @media #{$responsive-phone} {
        h2, p {
          text-align: center;
        }
      }
    }
  }

  // .section--clients
  &--clients {
    background-color: $primary;
    background-image: url('/image/bg_clients.jpg');

    h1, h2, h3, h4, h5, h6, p {
      color: $white;
    }

    .section-title {
      color: $white;
    }
    
    .tabContentWrapper {
      background: transparent;
      box-shadow: none;
      @media #{$responsive-phone} {
        background-image: url('/image/watermark_dark.png'), linear-gradient(45deg, rgba($neutral, 1) -50%, rgba($white, 1));
        background-repeat: no-repeat, no-repeat;
        background-position: bottom right, bottom right;
        background-attachment: fixed, scroll;
        box-shadow: -1rem 1rem 2rem 0.25rem rgba($black, 0.3);
      }
    }

    .tab {
      &Item {
        &.active {
          .tabLink {
            color: $white;
          }
        }
      }
      &Link {
        color: rgba($white, 0.5);
        &:hover, &:focus {
          color: $tertiary;
        }
      }
    }

    .tabContent {
      * {
        color: $black;
      }
      a {
        color: $yellow;
        &:hover, &:focus {
          color: lighten($yellow, 10%);
        }
      }
      .tabPageWrapper {
        h2 {
          color: $white;
          @media #{$responsive-phone} {
            color: $black;
          }
        }
      }
    }
    
    .list.horizontal {
      @include flex(flex-start, stretch, row, !important);

      .listItem {
        width: calc(25% - 2rem);
        margin: 1rem !important;
        break-inside: avoid;
        z-index: 1;
        position: relative;
        @include flex(stretch, stretch);
        @media #{$responsive-phone} {
          width: calc(100% - 0rem);
          padding: 0;
          margin: 0 !important;
          margin-bottom: 1rem !important;
        }

        img {
          background: radial-gradient(closest-corner, rgba($gray, 0), rgba($gray, 1)), $white;
          background-size: 300% 300%, 100%;
          background-repeat: no-repeat, no-repeat;
          background-position: center center, center center;
          border-radius: 0rem;
          padding: 2rem;
          box-shadow: 0rem 0.1rem 0.25rem rgba($black, 0.1);
          object-fit: contain;
          transition: border 0.15s ease-out, background 0.3s ease-out, transform 0.3s $bounce, box-shadow 0.15s ease-out;

          @media #{$responsive-phone} {
            padding: 1rem;
          }
        }

        &:hover {
          z-index: 2;
          img {
            background-size: 150% 150%, 100%;
            box-shadow: 0rem 0.25rem 0.5rem rgba($black, 0.3);
            transform: scale(1.3);
          }
        }
      }
    }
  }

  // .section--projects
  &--projects {
    background-color: $white;
    background-image: url('/image/bg_projects.jpg');
  }

  // .section--services
  &--services {
    background-color: $primary;
    background-image: url('/image/bg_services.jpg');

    .sectionWrapper {
      @media #{$responsive-phone} {
        padding: 4rem 0;
      }
    }

    .section-title {
      color: $white;
      text-transform: uppercase;
    }

    .pricelist {
      &Item {
        padding: 0 2rem;
        p {
          color: rgba($white, 0.7);
        }
        h3 {
          color: $white;
        }
        &--content {
        }
      }
    }
  }

  // .section--safety
  &--safety {
    background-color: $white;
    background-image: url('/image/bg_safety.jpg');

    .sectionWrapper {
      @media #{$responsive-phone} {
        padding: 4rem 1rem;
        padding-top: 2rem;
      }
    }

    .documentPaper {
      background-color: $white;
      width: 100%;
      max-width: 960px;
      margin: auto;
      padding: 2rem 4rem;
      border-radius: 2rem;
      box-shadow: 0rem 0rem 0.5rem 0.25rem rgba($black, 0.15);

      @media #{$responsive-phone} {
        padding: 2rem;
      }

      .list.bulleted {
        display: block;
        columns: 3 150px;
        column-gap: 2rem;
        .listItem {
          width: auto;
          margin: 0.5rem 0 !important;
          break-inside: avoid;
        }
      }
    }
  }

  // .section--portfolio
  &--portfolio {
    background-color: $gray;
    background-image: url('/image/portfolio_bg.jpg');
  }

  // .section--contact
  &--contact {
    background-color: $secondary;
    background-image: url('/image/bg_contact.jpg');

    h1, h3, h4 {
      color: $white;
    }

    p {
      color: rgba($white, 0.7);
    }

    .sectionWrapper {
      @media #{$responsive-phone} {
        padding: 4rem 2rem;
        padding-top: 2rem;
      }
    }

    .contactRow {
      @media #{$responsive-tablet} {
        flex-direction: column;
      }
    }

    .contactAddress {
      padding-right: 2rem;
      @media #{$responsive-tablet} {
        width: calc(100%) !important;
        margin: 0 0 4rem 0!important;
        text-align: center;
        padding-right: 0rem;
      }
      @media #{$responsive-phone} {
        width: calc(100%) !important;
        margin: 0 0 4rem 0!important;
        text-align: center;
        padding-right: 0rem;
      }
    }

    .contactForm {
      padding: 0;
      padding-bottom: 0rem;

      @media #{$responsive-tablet} {
        padding: 0;
        width: calc(100%) !important;
      }

      @media #{$responsive-phone} {
        padding: 0;
        width: calc(100%) !important;
      }

      .form {
        background: linear-gradient(45deg, rgba($secondary, 1) -20%, rgba($primary, 1));
        box-shadow: 0 0 2rem 0.25rem rgba($white, 0.025);
        border-bottom-left-radius: 2rem;
      }
    }

    .contactRemote {
      [class^=col] {
        @media #{$responsive-phone} {
          text-align: center;
        }
      }
    }
  }
}