h1, h2, h3, h4, h5, h6, p {
  margin: 1em 0;
}

h1 {
  font-size: 2.2rem;
  font-weight: 700;
  letter-spacing: -0.1rem;
}

h2 {
  font-size: 1.8rem;
  font-weight: 700;
  letter-spacing: -0.1rem;
}

h3 {
  font-size: 1.6rem;
  font-weight: 400;
}

h4 {
  font-size: 1.4rem;
  font-weight: 200;
}

h5 {
  font-size: 1.2rem;
  font-weight: 700;
}

h6 {
  font-size: 1rem;
  font-weight: 700;
  text-transform: uppercase;
}

.text {
  text-align: inherit;
  // .text--left
  &--left {
    text-align: left;
  }
  // .text--right
  &--right {
    text-align: right;
  }
  // .text--center
  &--center {
    text-align: center;
  }
  &--italic {
    font-style: italic;
  }
}

p {
  font-size: 1rem;
  line-height: 1.5em;
  span {
    display: block;
  }
}

.subtitle {
  color: rgba($black, 0.75) !important;
  &.light {
    color: rgba($white, 0.75) !important;
  }
}