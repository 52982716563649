// ========================
// THEME COLORS
// ========================
$primary: #D90429;
$secondary: #051833;
$tertiary: #fdc500;
$accent: #D90429;


// ========================
// BASIC COLORS
// ========================
$black: #1A1A1A;
$gray: #6c757d;
$lightgray: #edf0f3;
$darkgray: #212529;
$neutral: #c9baba;

$red: #FF4444;
$orange: #F98A1E;
$yellow: #fdc500;
$green: #30b407;
$jade: #008B98;
$teal: #1FC8B6;
$blue: #0060FF;
$indigo: #3A13B7;
$purple: #902BEE;
$violet: #E41DA5;
$white: #FFF;


// ========================
// SYSTEM COLORS
// ========================
$success: #35B035;
$warning: #F98A1E;
$danger: #AA2225;
$info: #4680FE;
$bgcolor: #ffffff;
$overlay: #1a1423;
$titlecolor: #212529;
$subtitlecolor: #30353a;


// ========================
// SIZES
// ========================
$font-size: 16px;
$navHeight: 5rem;


// ========================
// MEDIA QUERY
// ========================
$responsive-laptop: 'only screen and (max-width:1440px)';
$responsive-tablet: 'only screen and (min-device-width:768px) and (max-device-width:1024px), (min-width: 768px) and (max-width: 1024px)';
$responsive-phone: 'only screen and (min-device-width:320px) and (max-device-width:736px), (min-width:320px) and (max-width:736px)';
$responsive-portrait: 'and (orientation: portrait)';
$responsive-landscape: 'and (orientation: landscape)';

// TIMING FUNCTIONS
$bounce: cubic-bezier(0.175, 0.885, 0.32, 1.275);