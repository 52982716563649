.table {
  width: 100%;
  border: none;
  border-collapse: collapse;
  line-height: 1.5em;

  th, td {
    padding: 0.5rem 1rem;
    transition: background-color 0.15s ease-out;
  }

  thead {
    tr {
      th {
        border-bottom: 2px solid rgba($white, 0.5);
      }
    }
  }

  tbody {
    tr {
      td {
        border-bottom: 1px solid rgba($white, 0.15);
      }
      &:hover {
        td {
          background-color: rgba($white, 0.05);
        }
      }
    }
  }
}