.avatar {
  width: 100%;
  max-width: 180px;
  border-radius: 50%;
  overflow: hidden;
  margin: auto;
  margin-top: 2rem;
  padding: 2rem;
  background-color: $primary;
  img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
}