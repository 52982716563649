.nav {
  @include flex(flex-start, stretch);
  width: 100%;
  padding: 0 4rem;

  // .navList
  &List {
    @include flex(flex-end, stretch);
    flex-grow: 1;
    list-style: none;
    padding: 0rem;
    transition: all 0.15s ease-out;
  }

  // .navItem
  &Item {
    @include flex(center, center);
    margin: 0 1rem;
  }

  // .navLink
  &Link {
    color: $white;
    font-weight: bold;
    pointer-events: all;
    transition: all 0.15s ease-out;

    &:hover, &.active {
      color: lighten($primary, 15%);
    }
  }

  &.mainNav {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    max-height: $navHeight;
    z-index: 10;
    transition: all 0.15s ease-out;
    padding: 1rem 4rem;
    pointer-events: none;

    .navLink {
      span {
        text-transform: uppercase;
      }
    }

    @media #{$responsive-phone} {
      @include flex(center, center);
      .nav {
        // .navList
        &List {
          position: absolute;
          top: 0;
          right: -100%;
          height: 100vh;
          width: min(50vw, 260px);
          background-color: $darkgray;
          @include flex(flex-start, flex-end);
          flex-direction: column;
          padding: 4rem 0;
          box-shadow: -1rem 0 1rem rgba($black, 0.5);
        }
        // .navItem
        &Item {
          width: 100%;
          margin: 0;
        }
        // .navLink
        &Link {
          @include flex(flex-end, center);
          width: 100%;
          padding: 1rem 2rem;

          span {
            text-align: right;
          }
        }
      }
    }
    &.light {
      background-color: rgba($white, 0.9) !important;
      .nav {
        // .navList
        &List {
        }
        // .navItem
        &Item {
        }
        // .navLink
        &Link {
          color: $neutral;

          &:hover, &.active {
            color: $primary;
          }
        }
      }

      .burger {
        span {
          background-color: $darkgray;
        }
      }
      .branding {
        img {
          opacity: 0 !important;
          &.dark {
            opacity: 1 !important;
          }
        }
      }
    }
  }
}

.burger {
  position: absolute;
  right: 1rem;
  width: 2.2rem;
  height: 2.2rem;
  z-index: 2;
  transition: transform 0.15s ease-out;
  display: none;
  pointer-events: all;
  @media #{$responsive-phone} {
    @include flex(center, center);
    flex-wrap: wrap;
  }

  span {
    position: absolute;
    width: 2rem;
    height: 0.3rem;
    background-color: $white;
    display: block;
    transition: all 0.15s ease-out;

    &:nth-of-type(1) {
      transform: translateY(-0.65rem);
    }
    &:nth-of-type(3) {
      transform: translateY(0.65rem);
    }
  }
}

.branding {
  @include flex(flex-start, center);
  position: relative;
  pointer-events: all;
  height: inherit;
  img {
    width: auto;
    height: 100%;
    transition: all 0.15s ease-out;

    &.dark {
      position: absolute;
      opacity: 0;
    }
  }
}

.page-scrolled {
  .branding {
    img {
      opacity: 0 !important;
      &.dark {
        opacity: 1 !important;
      }
    }
  }
  .mainNav {
    // background-color: $white !important;
    max-height: calc(#{$navHeight} - #{$navHeight} / 4) !important;
    box-shadow: 0 1rem 1rem rgba($black, 0.1);
    &.light {
      img {
        opacity: 1 !important;
        &.dark {
          opacity: 0 !important;
        }
      }
    }
  }
  .nav {
    &Link {
      color: $neutral;
      &:hover, &.active {
        color: $primary;
      }
    }
  }

  .burger {
    span {
      background-color: $darkgray;
    }
  }
}

.menu-open {
  .mainNav {
    @media #{$responsive-phone} {
      .nav {
        &List {
          right: 0;
        }
      }
    }
    &.light {
      .burger {
        top: 1rem;
        span {
          background-color: $white;
        }
      }
    }
  }

  .burger {
    top: 1rem;
    transform: scale(0.5);
    span {
      &:nth-of-type(1) {
        transform: translateY(0rem) rotate(-45deg);
      }
      &:nth-of-type(2) {
        transform: scaleX(0);
      }
      &:nth-of-type(3) {
        transform: translateY(0rem) rotate(45deg);
      }
    }
  }
}


.page-scrolled.menu-open {
  .burger {
    top: 1rem;
    span {
      background-color: $white;
    }
  }
  .mainNav {
    .navLink {
      @media #{$responsive-phone} {
        color: $white;

        &:hover, &.active {
          color: $primary;
        }
      }
    }
    &.light {
      .burger {
        top: 1rem;
        span {
          background-color: $white;
        }
      }
    }
  }
}