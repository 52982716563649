.pricelist {
  @media #{$responsive-tablet} {
    flex-direction: column;
  }
  // .pricelisItem
  &Item {
    transition: transform 0.3s $bounce;
    z-index: 1;
    position: relative;
    // &:hover {
    //   transform: scale(1.2);
    //   z-index: 3;
    // }

    @media #{$responsive-tablet} {
      width: calc(100% - calc(#{$grid-spacing} * 2)) !important;
    }

    // .pricelistItem--content
    &--content {
      padding: 1rem 2rem;
      border-radius: 2rem;
      background: linear-gradient(45deg, rgba($secondary, 1) -50%, rgba($primary, 1));
      background-repeat: no-repeat;
      background-position: bottom right;
      background-attachment: scroll;
      box-shadow: -1rem 1rem 2rem 0.25rem rgba($black, 0.3);
    }
  }
}