@for $i from 1 through 50 {
  .animate__delay-#{$i * 100}ms  {
    animation-delay: #{$i * 100}ms !important;
    animation-fill-mode: both;
  }
}
@for $i from 1 through 50 {
  .animate__duration-#{$i * 100}ms  {
    animation-duration: #{$i * 100}ms !important;
    animation-fill-mode: both;
  }
}