.form {
  // @include flex(flex-start, flex-start);
  display: block;
  flex-wrap: nowrap;
  width: 100%;
  position: relative;
  overflow: hidden;

  // .form-header
  &-header {
    padding: 2rem 4rem;
    border-bottom: 1px solid rgba($white, 0.1);

    h1 {
      margin: 0;
      color: $white;
    }

    &.smaller {
      padding: 1rem 4rem;
      h1 {
        font-size: 1.5rem;
        font-weight: lighter;
      }
    }

    @media #{$responsive-phone} {
      padding: 2rem;
    }
  }

  input {
    &[type='hidden'] {
      visibility: hidden;
      width: 0;
      height: 0;
    }
  }

  // .form-input
  &-input {
    @include flex(flex-start, flex-start);
    width: 100%;
    transition: background 0.15s ease-out;
    padding: 0.5rem 4rem;
    @media #{$responsive-phone} {
      padding: 0.5rem 2rem;
      padding-bottom: 1rem;
      flex-direction: column;
    }

    &:not(:last-of-type) {
      border-bottom: 1px solid rgba($white, 0.1);
    }

    label {
      width: 40%;
      color: rgba($white, 0.7);
      padding: 0.5rem 0;
      padding-top: 0.65rem;

      @media #{$responsive-phone} {
        width: 100%;
      }

      span {
        font-size: 0.8rem;
        color: rgba($white, 0.35);
        line-height: 1.2rem;
        &.br {
          display: block;
          margin-top: 0rem;
        }
      }
    }

    input, textarea {
      flex-grow: 1;
      border: 1px solid rgba($white, 0);
      background: rgba($white, 0.1);
      padding: 0.5rem 1rem;
      outline: none;
      color: $white;
      line-height: 1.5em;
      transition: background 0.15s ease-out, color 0.15s ease-out;

      &[type='file'] {
        flex-grow: 0;
        width: calc(100% - 14.5rem);
        display: block;
        @media #{$responsive-phone} {
          width: 100%;
        }
      }

      &:hover, &:focus {
        background: rgba($white, 0.3);
      }
      @media #{$responsive-phone} {
        width: 100%;
      }
    }

    &:hover, &:focus {
      background: rgba($white, 0.02);
    }

    &.error {
      input, textarea {
        background: rgba($red, 0.5);
      }
    }
  }

  // .form-actions
  &-actions {
    padding: 0 4rem;
    text-align: right;
    position: relative;
    z-index: 2;

    @media #{$responsive-phone} {
      padding: 0 2rem;
    }
  }

  // .form-loader
  &-loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
    background: rgba($black, 0.85);
    @include flex(center, center);
    transition: opacity 0.15s ease-out;
    opacity: 0;
    pointer-events: none;
  }

  // .form-set
  &-set {
    display: inline-block;
    width: 100%;
    transition: transform 0.5s $bounce;
  }

  // .form-confirmation
  &-confirmation {
    width: 100%;
    padding: 4rem;
    @include inline-flex(center, center, column);
    transition: transform 0.5s $bounce;
    position: absolute;
    top: 0;
    left: 0;
    transform: translateX(100%);

    .confirmationCheck {
      @media #{$responsive-phone} {
        @include flex(center, center);
      }
    }
    .confirmationText {
      @media #{$responsive-phone} {
        text-align: center;
      }
    }
  }

  // EVENTS
  &.processing {
    .form-loader {
      opacity: 1;
      pointer-events: all;
    }
  }

  &.success {
    .form-loader {
      opacity: 0;
      pointer-events: none;
    }

    .form-set {
      transform: translateX(-100%);
    }
    .form-confirmation {
      transform: translateX(0%);
    }
  }
}