* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: $yellow;
  transition: color 0.15s ease-out;
  &:hover, &:focus {
    color: lighten($yellow, 10%);
  }
}

img {
  width: 100%;
  height: auto;
}

body {
  width: 100vw;
  height: 100vh;
  font-size: $font-size;
  background-color: $bgcolor;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.9));
  color: $gray;
  font-family: 'Montserrat', sans-serif;
  overflow-y: hidden;
  overflow-x: hidden;

  .animate__animated {
    animation-duration: 0.3s !important;
    animation-delay: 0.3s !important;
  }

  .animated {
    opacity: 0;
    animation-fill-mode: both;
  }
  *[class*=animate__] {
    animation-fill-mode: both;
    animation-delay: 0ms;
    animation-duration: 500ms;
  }

  &.transitioning {
    .main {
      .contentWrapper {
        overflow-y: hidden;
        overflow-x: hidden;
      }
    }

    // .transitionOverlay {
    //   opacity: 1;
    //   // transition: none;
    // }

    .ps__rail-y, .ps__rail-x {
      display: none;
    }

    .mainNav {
      .navLink {
        pointer-events: none;
      }
    }
  }
}

.main {
  position: relative;
  perspective: 100vw;
  .content {
    transform-origin: center center;
    position: relative;
    z-index: 1;
    overflow: hidden;
    transform: scale(1);
    opacity: 1;
  }
  .contentWrapper {
    position: relative;
    width: 100vw;
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.transitionOverlay {
  position: absolute;
  z-index: 3;
  display: none;
  pointer-events: none;
  height: auto;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  
  // .transitionOverlay__cover
  &__cover {
    @include overlay(5, 1, $overlay);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
  }

  // .transitionOverlay__spin
  &__spin {
    width: 300px;
    height: 300px;
    display: none;
    opacity: 0;

    border-radius: 50%;
    // background-color: $white;
    background-color: transparent;
    background-image: url(/image/icon_light.png);
    background-size: 80px;
    background-position: center center;
    background-repeat: no-repeat;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transform-origin: center center;
    z-index: 6;
    margin-top: -3rem;
  }

  // .transitionOverlay__lottie
  &__lottie {
    width: 300px;
    height: 300px;

    lottie-player {
      width: 100% !important;
      height: 100% !important;
    }
  }
}

.footer {
  position: relative;
  padding: 2rem 4rem;
  min-height: 40vh;
  background-color: rgba($primary, 1);
  background-image: url('/image/bg_footer.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom center;
  color: $white;
  @include flex(center, flex-start);
  line-height: 1.5em;

  .watermark {
    text-align: center;
    img {
      width: 100%;
      max-width: 120px;
      margin-top: 1rem;
    }
  }

  .wrapper {
    width: 100%;
    max-width: 1200px;

    > .row {
      > [class^='col'] {
        margin-bottom: 0;
      }
    }
  }

  a {
    color: $white;

    &:hover {
      color: $yellow;
    }
  }
}

.scrollTop {
  position: fixed;
  bottom: 1rem;
  right: 2rem;
  z-index: 1000;
  transition: all 0.15s ease-out;
  opacity: 0;
  pointer-events: none;
  a {
    @include flex(center, center);
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    background-color: $white;
    box-shadow: 0 0rem 0.5rem 0.25rem rgba($black, 0.25);
    transform: scale(0.8);
    transition: all 0.15s $bounce;

    lottie-player {
      width: 100% !important;
      height: 100% !important;
    }

    &:hover {
      box-shadow: 0 0.1rem 0.5rem 0.25rem rgba($black, 0.5);
      transform: scale(1);
    }
  }
}

.page-scrolled {
  .scrollTop {
    opacity: 1;
    pointer-events: all;
  }
}

.no-scroll {
  .main .contentWrapper {
    overflow: hidden !important;
  }
}

.ps__rail-y, .ps__rail-x {
  z-index: 1000;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: rgba($black, 0.3);
  margin-top: 2rem;
  margin-bottom: 2rem;

  &.light {
    background-color: rgba($white, 0.3);
  }

  &.bigger {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
}