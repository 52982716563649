.btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 2rem;
  width: auto;
  font-weight: bold;
  transition: background 0.15s ease-out, border 0.15s ease-out, color 0.15s ease-out, box-shadow 0.15s ease-out;
  border-width: 1px;
  border-style: solid;
  border-radius: 4em;
  overflow: hidden;
  border-color: rgba($white, 0.5);
  color: $darkgray;
  position: relative;
  z-index: 1;
  outline: none;
  box-shadow: 0 0 0.5em 0.2em rgba($white, 0);
  cursor: pointer;

  margin: 1rem 0;
  letter-spacing: 0;
  text-transform: uppercase;
  
  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    border-radius: 2rem;
    transform: scale(0);
    background-color: darken($white, 10%);
    transition: background 0.15s ease-out, transform 0.15s ease-out;
  }
  
  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -2;
    top: 0;
    left: 0;
    background-color: rgba($white, 1);
    transition: background-color 0.15s ease-out, width 0.15s ease-out;
  }

  &:hover, &:focus {
    color: $darkgray;
    border-color: rgba($white, 1);
    box-shadow: 0 0 0.5em 0.2em rgba($white, 0.5);
    &:before {
      transform: scale(1);
    }
  }

  // .btn--bigger
  &--bigger {
    padding: 1.25rem 3rem;
    font-size: 1.5rem;
    border-radius: 5em;
    letter-spacing: -0.1rem;
  }

  // .btn--smaller
  &--smaller {
    padding: 0.5rem 1rem;
    font-size: 0.75rem;
    letter-spacing: 0.1rem;
  }

  // .btn--primary
  &--primary {
    border-color: $primary;
    color: $white;
    &:before {
      background-color: darken($primary, 5%);
    }
    &:after {
      background-color: rgba($primary, 1);
    }
    &:hover, &:focus {
      color: $white;
      border-color: rgba($primary, 1);
      box-shadow: 0 0 0.5em 0.2em rgba($primary, 0.5);
    }
  }

  // .btn--secondary
  &--secondary {
    border-color: $secondary;
    color: $white;
    &:before {
      background-color: darken($secondary, 5%);
    }
    &:after {
      background-color: rgba($secondary, 1);
    }
    &:hover, &:focus {
      color: $white;
      border-color: rgba($secondary, 1);
      box-shadow: 0 0 0.5em 0.2em rgba($secondary, 0.5);
    }
  }

  // .btn--tertiary
  &--tertiary {
    border-color: $tertiary;
    color: $white;
    &:before {
      background-color: darken($tertiary, 5%);
    }
    &:after {
      background-color: rgba($tertiary, 1);
    }
    &:hover, &:focus {
      color: $white;
      border-color: rgba($tertiary, 1);
      box-shadow: 0 0 0.5em 0.2em rgba($tertiary, 0.5);
    }
  }

  // .btn--success
  &--success {
    border-color: $success;
    color: $white;
    &:before {
      background-color: darken($success, 5%);
    }
    &:after {
      background-color: rgba($success, 1);
    }
    &:hover, &:focus {
      color: $white;
      border-color: rgba($success, 1);
      box-shadow: 0 0 0.5em 0.2em rgba($success, 0.5);
    }
  }

  // .btn--info
  &--info {
    border-color: $info;
    color: $white;
    &:before {
      background-color: darken($info, 5%);
    }
    &:after {
      background-color: rgba($info, 1);
    }
    &:hover, &:focus {
      color: $white;
      border-color: rgba($info, 1);
      box-shadow: 0 0 0.5em 0.2em rgba($info, 0.5);
    }
  }

  // .btn--warning
  &--warning {
    border-color: $warning;
    color: $white;
    &:before {
      background-color: darken($warning, 5%);
    }
    &:after {
      background-color: rgba($warning, 1);
    }
    &:hover, &:focus {
      color: $white;
      border-color: rgba($warning, 1);
      box-shadow: 0 0 0.5em 0.2em rgba($warning, 0.5);
    }
  }

  // .btn--danger
  &--danger {
    border-color: $danger;
    color: $white;
    &:before {
      background-color: darken($danger, 5%);
    }
    &:after {
      background-color: rgba($danger, 1);
    }
    &:hover, &:focus {
      color: $white;
      border-color: rgba($danger, 1);
      box-shadow: 0 0 0.5em 0.2em rgba($danger, 0.5);
    }
  }

  // MODIFIERS
  // .btn--line
  &--line {
    border-width: 1px;
    border-style: solid;
    border-radius: 4em;
    overflow: hidden;
    border-color: rgba($white, 0.5);
    color: $white;
    position: relative;
    outline: none;
    margin: 1rem 0;
    
    &:before {
      background-color: $white;
    }
    
    &:after {
      display: none;
    }
  
    &:hover, &:focus {
      color: $darkgray;
      border-color: rgba($white, 1);
      box-shadow: 0 0 0.5em 0.2em rgba($white, 0.5);
      &:before {
        transform: scale(1);
      }
    }

    &.btn {
      // .btn--primary
      &--primary {
        border-color: $primary;
        color: lighten($primary, 10%);
        &:before {
          background-color: $primary;
        }
        &:hover, &:focus {
          color: $white;
          border-color: rgba($primary, 1);
          box-shadow: 0 0 0.5em 0.2em rgba($primary, 0.5);
        }
      }
      &--secondary {
        border-color: $secondary;
        color: lighten($secondary, 10%);
        &:before {
          background-color: $secondary;
        }
        &:hover, &:focus {
          color: $white;
          border-color: rgba($secondary, 1);
          box-shadow: 0 0 0.5em 0.2em rgba($secondary, 0.5);
        }
      }
      &--tertiary {
        border-color: $tertiary;
        color: lighten($tertiary, 10%);
        &:before {
          background-color: $tertiary;
        }
        &:hover, &:focus {
          color: $white;
          border-color: rgba($tertiary, 1);
          box-shadow: 0 0 0.5em 0.2em rgba($tertiary, 0.5);
        }
      }
      &--success {
        border-color: $success;
        color: lighten($success, 10%);
        &:before {
          background-color: $success;
        }
        &:hover, &:focus {
          color: $white;
          border-color: rgba($success, 1);
          box-shadow: 0 0 0.5em 0.2em rgba($success, 0.5);
        }
      }
      &--info {
        border-color: $info;
        color: lighten($info, 10%);
        &:before {
          background-color: $info;
        }
        &:hover, &:focus {
          color: $white;
          border-color: rgba($info, 1);
          box-shadow: 0 0 0.5em 0.2em rgba($info, 0.5);
        }
      }
      &--warning {
        border-color: $warning;
        color: lighten($warning, 10%);
        &:before {
          background-color: $warning;
        }
        &:hover, &:focus {
          color: $white;
          border-color: rgba($warning, 1);
          box-shadow: 0 0 0.5em 0.2em rgba($warning, 0.5);
        }
      }
      &--danger {
        border-color: $danger;
        color: lighten($danger, 10%);
        &:before {
          background-color: $danger;
        }
        &:hover, &:focus {
          color: $white;
          border-color: rgba($danger, 1);
          box-shadow: 0 0 0.5em 0.2em rgba($danger, 0.5);
        }
      }
    }
  }
}

.btnGroup {
  @include flex(flex-start, center);
  flex-direction: row;
  .btn {
    &:not(:last-of-type) {
      margin: 0 0.5rem 0 0;
    }
  }

  &.vertical {
    align-items: flex-start;
    flex-direction: column;
    .btn {
      &:not(:last-of-type) {
        margin: 0 0 0.5rem 0;
      }
    }
  }

  &.right {
    justify-content: flex-end;
    &.vertical {
      justify-content: flex-start;
      align-items: flex-end;
    }
  }

  &.center {
    justify-content: center;
    &.vertical {
      align-items: center;
    }
  }

  &.line {
    padding-top: 1rem;
    margin-top: 1rem;
    border-top: 1px solid rgba($black, 0.5);

    &.light {
      border-top: 1px solid rgba($white, 0.5);
    }
  }
}


.socialLink {
  outline: none;
  text-decoration: none;
  display: inline-block;
  &:hover {
    .socialIcon {
      background-color: rgba($white, 0.5);
    }
  }
}
.socialIcon {
  display: block;
  line-height: 0;
  width: calc(min(12vw, 3rem));
  height: calc(min(12vw, 3rem));
  border-radius: calc(min(12vw, 3rem));
  padding: 0.25rem;
  object-fit: contain;
  object-position: center center;
  background-color: rgba($white, 1);
  transition: 0.15s ease-out;
  overflow: hidden;
}