.modal {
  position: relative;
  z-index: 1001;
  display: none;

  &.show {
    display: block;
  }

  .wrapper {
    width: 100%;
    padding: 1rem 2rem;
  }

  // .modalWrapper
  &Wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba($black, 0.75);
    overflow-y: hidden;
    overflow-x: hidden;
    @include flex(center, center);
  }

  // .modalContainer
  &Container {
    width: 100%;
    max-width: 720px;
    background: linear-gradient(45deg, rgba($secondary, 1) -20%, rgba($primary, 1));
    box-shadow: 0 0 2rem 0.25rem rgba($white, 0.025);

    @include flex(flex-start, flex-start, column);

    @media #{$responsive-phone} {
      height: 100%;
    }
  }

  // .modalHeader
  &Header {
    width: 100%;
    .wrapper {
      padding-right: 1.25rem;
      @include flex(flex-start, center);
    }
    border-bottom: 1px solid rgba($white, 0.25);
    color: $white;
    h1 {
      margin: 0;
      flex-grow: 1;
      padding-right: 1rem;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      @media #{$responsive-phone} {
        font-size: 1.5rem;
        white-space: normal;
      }
    }
    .close {
      min-width: 50px;
      min-height: 50px;
      max-width: 50px;
      max-height: 50px;
      position: relative;
      padding: 0.5rem;
      border-radius: 50%;
      border: 1px solid rgba($tertiary, 0);
      @include inline-flex(center, center);
      transition: border 0.15s ease-out;
      img {
        width: 100%;
        height: auto;
        transition: transform 0.15s ease-out;
      }
      &:hover {
        border: 1px solid rgba($tertiary, 1);
        img {
          transform: scale(0.8);
        }
      }
    }
  }

  // .modalBody
  &Body {
    flex-grow: 1;
    width: 100%;

    @media #{$responsive-phone} {
      overflow-y: auto;
    }

    .wrapper {
      padding: 2rem;
    }
  }

  // .modalFooter
  &Footer {
    padding: 0rem 2rem;
    border-top: 1px solid rgba($white, 0.25);
    .wrapper {
      padding: 0;
    }
  }
}