.tab {
  list-style: none;
  margin: 0;

  @include flex(flex-start, center);

  // .tabItem
  &Item {
    &.active {
      .tabLink {
        color: $primary;
      }
    }
  }

  // .tabLink
  &Link {
    color: $neutral;
    @include flex(flex-start, flex-start, column);

    small {
      margin-top: 0.5rem;
      opacity: 0.75;
      font-weight: normal;
    }
  }

  &.vertical {
    @include flex(flex-start, flex-start);
    flex-direction: column;
  }

  // .tabContent
  &Content {
    display: block;
    overflow: hidden;
  }
  
  // .tabPage
  &Page {
    display: none;
    opacity: 0;
    animation-delay: 0s;
    animation-duration: 0.15s;
    animation-timing-function: ease-out;
    animation-fill-mode: both;
    animation-iteration-count: 1;

    &.active {
      display: block;
      animation-name: showTab;
    }

    @keyframes showTab {
      0% {
        display: none;
        opacity: 0;
      }
      1% {
        display: block;
        opacity: 0;
      }
      100% {
        display: block;
        opacity: 1;
      }
    }
  
    @keyframes hideTab {
      0% {
        display: block;
        opacity: 1;
      }
      99% {
        display: block;
        opacity: 0;
      }
      100% {
        display: none;
        opacity: 0;
      }
    }
  }
}